import { useState } from "react";
import { Dialog, Group, Button, TextInput, Text } from "@mantine/core";
import { PasswordInput } from "@mantine/core";
import baseURL from "./api";

export const VerifyNico = ({ isNico, setIsNico }) => {
  const [opened, setOpened] = useState(false);
  const [pwd, setPwd] = useState("");

  const verifyNicoApi = async () => {
    try {
      const response = await fetch(`${baseURL}/nico/?request=${pwd}`);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      if (data) {
        setIsNico(true);
      } else {
        setIsNico(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      <Group position="center">
        <Button
          size="sm"
          variant="outline"
          color="dark"
          onClick={() => setOpened((o) => !o)}
        >
          Nico Login
        </Button>
      </Group>

      <Dialog
        opened={opened}
        withCloseButton
        onClose={() => setOpened(false)}
        size="xl"
        radius="md"
        style={{
          backgroundImage: `url('https://niconsultaniconsejo.s3.eu-west-2.amazonaws.com/website/wtf.webp')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          // Add other background-related styles as needed
        }}
      >
        <div className="flex flex-col space-y-5">
          <PasswordInput
            className="mt-60"
            placeholder="solo nico sabe cual la secretísima contraseña"
            variant="filled"
            size="lg"
            value={pwd}
            onChange={(event) => setPwd(event.currentTarget.value)}
          />
          <Button
            disabled={isNico || pwd === ""}
            variant="outline"
            color="white"
            onClick={() => {
              verifyNicoApi();
            }}
          >
            {" "}
            Login
          </Button>
          <div className="font-mono text-lg text-center font-bold text-gray-200">
            {isNico ? "verificado campeón!" : "u are not nico!!!"}
          </div>
        </div>
      </Dialog>
    </>
  );
};
