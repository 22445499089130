import { Textarea, Button } from "@mantine/core";
import { useState } from "react";
import baseURL from "./api";
import "./newspaper.css";

export const ConsejoCardToFill = ({ id, question }) => {
  const [answer, setAnswer] = useState("");
  const [answerSent, setAnswerSent] = useState(false);
  const updateAnswer = async () => {
    try {
      const response = await fetch(`${baseURL}/consejos/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: id, answer: answer, question: question }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Handle response if needed
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center space-y-3 border border-gray-500 collumn w-full">
      <p className="ml-3 mr-3 font-semibold text-gray-900 text-justify mt-3	">
        <span className=" text-bold text-xl">Q: </span> {question}
      </p>

      <Textarea
        disabled={answerSent}
        placeholder="Escribe aquí tus consejitos & dale a enviar!!"
        label="Answer:"
        value={answer}
        onChange={(event) => setAnswer(event.currentTarget.value)}
        className="p-3"
      />
      <div className="p-5">
        <Button
          disabled={answerSent}
          color="dark"
          variant="outline"
          onClick={() => {
            if (answer !== "") {
              updateAnswer();
              setAnswerSent(true);
            }
          }}
        >
          Enviar
        </Button>
      </div>
    </div>
  );
};
