import { Divider } from "@mantine/core";
import "./newspaper.css";

export const ConsejoCard = ({ question, answer, title, pseudonym }) => {
  return (
    //<div className="flex flex-col items-center justify-between space-y-7 w-full">
    //  <div className="text-4xl font-serif text-gray-800">{question}</div>

    <div className="border border-gray-500 collumn w-full">
      <div className="head">
        <span className="headline hl3">{title}</span>
        <p>
          <span className="headline hl4 ">by {pseudonym}</span>
        </p>
      </div>
      <p className="ml-3 mr-3 font-semibold text-gray-900 text-justify	">
        <span className=" text-bold text-xl">Q: </span> {question}
      </p>
      <p className="ml-3 mr-3 text-justify	">
        <span className=" text-bold font-semibold text-xl">A: </span> {answer}
      </p>
    </div>
  );
};
