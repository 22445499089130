import { MantineProvider, Divider, SimpleGrid } from "@mantine/core";
import "@mantine/core/styles.css";
import { useEffect } from "react";
import { ConsejoCard } from "./components/ConsejoCard";
import { useState } from "react";
import { ConsejoAsk } from "./components/ConsejoAsk";
import { VerifyNico } from "./components/VerifyNico";
import { ConsejoCardToFill } from "./components/ConsejoCardToFill";
import { WebsiteHeader } from "./components/WebsiteHeader";
import baseURL from "./components/api";
function App() {
  const [consejos, setConsejos] = useState([]);
  const [consejosNico, setConsejosNico] = useState([]);
  const [isNico, setIsNico] = useState(false);
  useEffect(() => {
    // Define a function to make the GET request
    const fetchData = async () => {
      try {
        const response = await fetch(`${baseURL}/consejos`);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const jsonData = await response.json();
        // set the ones with answer == "" to consejosNico and the rest to consejos
        setConsejos(
          jsonData.consejos.filter((consejo) => consejo.answer !== "")
        );
        setConsejosNico(
          jsonData.consejos.filter((consejo) => consejo.answer === "")
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the function when the component mounts
    fetchData();

    // Clean up function (optional)
    return () => {
      // Perform any cleanup (if necessary)
    };
  }, [isNico]);
  return (
    <MantineProvider>
      <div className="flex flex-col items-center w-full h-full">
        <WebsiteHeader />
        <div className="flex flex-col items-center space-y-5 w-2/3">
          <SimpleGrid cols={3}>
            {consejos.map((consejo) => (
              <div>
                <ConsejoCard
                  key={consejo.id}
                  question={consejo.question}
                  answer={consejo.answer}
                  title={consejo.title || consejo.question.substring(0, 16)}
                  pseudonym={consejo.pseudonym || "Anónimo"}
                />
              </div>
            ))}
          </SimpleGrid>
          <div className="border border-black w-screen"> </div>
          <div className="flex flex-row justify-center items-stretch">
            <div className="p-2 text-2xl font-serif mr-10">
              zona para ti, nico: a dar consejos!!
            </div>
            <VerifyNico isNico={isNico} setIsNico={setIsNico} />
          </div>

          <div className="border border-black w-screen"> </div>
          <SimpleGrid cols={3}>
            {isNico ? (
              consejosNico.map((consejo) => (
                <div>
                  <ConsejoCardToFill
                    key={consejo.id}
                    question={consejo.question}
                    id={consejo.id}
                  />
                </div>
              ))
            ) : (
              <></>
            )}
          </SimpleGrid>
        </div>
        <div className="mt-15 text-sm"> by aina, gus, dome; with love</div>
      </div>
    </MantineProvider>
  );
}

export default App;
