import { useDisclosure } from "@mantine/hooks";
import { Modal } from "@mantine/core";
import { useState } from "react";
import { useEffect } from "react";
import {
  Card,
  Image,
  Text,
  Badge,
  Button,
  Group,
  Textarea,
} from "@mantine/core";
import baseUrl from "./api.js";

export const ConsejoAsk = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const [question, setQuestion] = useState("");
  const [sent, setSent] = useState(false);
  useEffect(() => {
    setQuestion("");
    setSent(false);
  }, [opened]);
  const sendQuestion = async () => {
    try {
      const response = await fetch(`${baseUrl}/consejos`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ question: question, answer: "", id: "" }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      // Handle response if needed
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Modal size="xl" opened={opened} onClose={close}>
        <div className="flex flex-col space-y-10">
          <div className="flex items-center h-1/5">
            <img
              className=" max-h-96 border border-gray-200 shadow-sm rounded-lg"
              src={
                sent
                  ? "https://niconsultaniconsejo.s3.eu-west-2.amazonaws.com/website/kissed.webp"
                  : "https://niconsultaniconsejo.s3.eu-west-2.amazonaws.com/website/pelo_largo.webp"
              }
            />
            <div className="ml-10 text-2xl font-bold tracking-tight text-gray-900 ">
              {sent
                ? "No te preocupes mijo, ya estoy aquí"
                : "Whatchu need son?"}
            </div>
          </div>
          <div className="h-1/2">
            <Textarea
              size="xl"
              color="gray"
              value={question}
              onChange={(event) => setQuestion(event.currentTarget.value)}
            />
          </div>
          <>
            {sent ? (
              <Button disabled>sent!</Button>
            ) : (
              <Button
                variant="outline"
                color="gray"
                onClick={() => {
                  setSent(true);
                  if (question) {
                    sendQuestion();
                  }
                }}
              >
                Ask Nico
              </Button>
            )}
          </>
        </div>
      </Modal>

      <div className="ml-8">
        <Button variant="outline" color="gray" size="md" w="200" onClick={open}>
          Ask me anything
        </Button>
      </div>
    </>
  );
};
