import Subheader from "../images/advice.png";
import HeaderImg from "../images/NiConsultaNiConsejo.png";
import { ConsejoAsk } from "./ConsejoAsk";
import "./newspaper.css";

export const WebsiteHeader = () => {
  return (
    <div>
      <div className="flex flex-row justify-between group">
        <div className="flex flex-col w-1/5 ml-10">
          <div className="flex flex-row justify-center">
            <img
              src="https://niconsultaniconsejo.s3.eu-west-2.amazonaws.com/website/normal.webp"
              alt="nico"
              className="mt-2 mr-12 h-[225px]  rounded-full p-3 group-hover:hidden"
            />
            <img
              src="https://niconsultaniconsejo.s3.eu-west-2.amazonaws.com/website/angry.webp"
              alt="nico"
              className=" mt-2 mr-12 hidden h-[225px] rounded-full p-3 group-hover:block"
            />
          </div>
          <ConsejoAsk></ConsejoAsk>
        </div>
        <div className="flex flex-col w-4/5 mr-10 p-5">
          <img src={HeaderImg} alt="Header" />
          <img src={Subheader} alt="Subheader" />
        </div>
      </div>

      <div className="head mt-7">
        <div className="subhead">Edinburgh, SC - Friday March 15, 2024</div>
      </div>
    </div>
  );
};
